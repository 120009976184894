/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import classNames from "classnames";
import { Sidebar, Iframe } from "./components";
// import "./App.scss";

import list from "./assets/site-links.js";
const target = "URL_HERE";

function App() {
  const [links, setLinks] = useState([]);
  const [iframeLinks, setIframeLinks] = useState([]);
  const [showIframeOnly, setShowIframeOnly] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  useEffect(() => {
    const links = JSON.parse(localStorage.getItem("site-links")) || [];
    list.sort((a, b) => (a.name > b.name ? 1 : -1));
    const mappedArr = list.map((x) => {
      const index = links.findIndex((y) => y.id === x.id);
      if (index > -1) {
        x.selected = links[index].selected;
        x.favorite = links[index].favorite;
      }
      return x;
    });
    setLinks(mappedArr);
  }, [list]);

  function _handleClick(state) {
    const arr = [...links].map((x) => {
      if(showIframeOnly && !x.allowIframe && state) {
        x.selected = false;
      } else {
        x.selected = !!state 
      }
      return x;
    });
    const storage = arr.map((x) => ({ id: x.id, selected: x.selected }));
    localStorage.setItem("site-links", JSON.stringify(storage));
    setLinks(arr);

    if (!state) {
      setIframeLinks(null);
    }
  }

  function _toggleIframeOnly(show) {
    setShowIframeOnly(show);
    if (show) {
      const arr = [...links].map((x) => {
        if (!x.allowIframe) {
          x.selected = false;
        }
        return x;
      });
      setLinks(arr);
    }
  }

  function _handleSubmit(search, newTabs) {
    const iframeLinks = JSON.parse(JSON.stringify([...list])).filter(
      (x, index) => {
        x.url = x.url.replace(target, search);
        x.active = index === 0 ? true : false;
        return x.selected;
      }
    );
    if (newTabs) {
      iframeLinks.forEach((x) => {
        if (x.selected && newTabs) {
          window.open(x.url, "_blank");
        }
      });
    } else {
      iframeLinks[0].active = true;
      setIframeLinks(iframeLinks);
    }
  }

  function _selectTab(tab) {
    const arr = [...iframeLinks].map((x) => {
      x.active = false
      return x;
    });
    setIframeLinks(arr);
    setTimeout(() => {
      const arr = [...iframeLinks].map((x) => {
        x.active = x.id === tab.id;
        return x;
      });
      setIframeLinks(arr);
    })
  }

  const iframeLink = iframeLinks?.filter((x) => x.active)[0];
  const contentClasses = classNames(
    "flex-1 flex w-full flex-col overflow-hidden bg-gray-100 z-20",
    "transition-all duration-1000 transform ease-in-out",
    { "ml-52": isSidebarOpen }
  );

  return (
    <main className="flex-1 flex h-full">
      <Sidebar
        toggleIframeOnly={_toggleIframeOnly}
        showIframeOnly={showIframeOnly}
        handleSubmit={_handleSubmit}
        handleClick={_handleClick}
        setLinks={setLinks}
        setIsOpen={setIsSidebarOpen}
        isOpen={isSidebarOpen}
        links={links}
      />
      <content id="app-content" className={contentClasses}>
        {iframeLinks?.length ? (
          <>
            <ul className="iframe-tabs bg-white">
              {iframeLinks.map((link) => {
                return (
                  <li
                    key={link.id}
                    className={link.active ? "active text-sm" : "text-sm"}
                    onClick={() => _selectTab(link)}
                  >
                    {link.name}
                  </li>
                );
              })}
            </ul>
            <div className="flex-1 w-full flex flex-col overflow-hidden justify-center items-center w-full">
              {iframeLink && (
                <>
                  {iframeLink?.allowIframe ? (
                    <Iframe id={iframeLink.id} src={iframeLink.url} />
                  ) : (
                    <a
                      href={iframeLink.url}
                      className="text-blue-500"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Iframe not allowed. Click here to open a new tab.
                    </a>
                  )}
                </>
              )}
            </div>
          </>
        ) : (
          <div className="flex-1 flex flex-col justify-center m-auto max-w-md px-4">
            <h2 className="text-3xl font-medium mb-4">Welcome!</h2>
            <p className="leading-tight mb-3">
              Search for free images conveniently via the sidebar search.
            </p>
            <ol
              className="list-decimal"
              style={{ marginLeft: 0, paddingLeft: "20px", lineHeight: "1.1" }}
            >
              <li style={{ marginBottom: "10px" }}>
                You may need to grant pop-up permissions when opening multiple
                tabs.
              </li>
              <li style={{ marginBottom: "10px" }}>
                Your selection is saved automatically.
              </li>
            </ol>
          </div>
        )}
      </content>
    </main>
  );
}

export default App;
