import React from 'react'
import classNames from 'classnames'

export function Input(props) {
  let { tag: Tag, innerRef, size, name, value, className, onChange, isInvalid, ...attributes } = props

  const classes = classNames(
    className,
    'border border-solid rounded focus:border-black transition-colors duration-300',
    isInvalid ? 'bg-red-100 border-red-300' : '',
    { 'py-1 px-2 text-sm' : size === 'sm'},
    { 'py-2 px-3' : size === 'md' || !size},
  )

  Tag = Tag || 'input'

  return (
    <Tag
      ref={innerRef}
      name={name}
      value={value}
      onChange={onChange}
      className={classes}
      {...attributes}
    />
  )
}

export default Input;