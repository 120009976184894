export const list = [
  {
    "name": "StockSnap.io",
    "id": "eef48d35-a920-4408-a933-41565a84d46c",
    "selected": false,
    "url": "https://stocksnap.io/search/URL_HERE",
    "allowIframe": false,
    "favorite": false
  },
  {
    "name": "Burst by Shopify",
    "id": "eef5fd25-82ff-403e-9a42-20d917550c63",
    "selected": false,
    "url": "https://burst.shopify.com/photos/search?utf8=%E2%9C%93&q=URL_HERE",
    "allowIframe": false,
    "favorite": false
  },
  {
    "name": "Dreamstime",
    "id": "5cedd1f7-46a4-4648-bb6f-37fb8da2bcdb",
    "selected": false,
    "url": "https://www.dreamstime.com/search.php?securitycheck=fb1e42f314615e1f830c8c2779442f21&firstvalue=&lastsearchvalue=&srh_field=URL_HERE&s_ph=y&s_il=y&s_video=y&s_audio=y",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "Unsplash",
    "id": "5a4b245c-bcc0-4cd6-8ab6-3fa44028f42a",
    "selected": false,
    "url": "https://unsplash.com/s/photos/URL_HERE",
    "allowIframe": false,
    "favorite": false
  },
  {
    "name": "Pixabay",
    "id": "d7756dbe-5dce-4bc8-8f7a-13b36cb55a27",
    "selected": false,
    "url": "https://pixabay.com/images/search/URL_HERE/",
    "allowIframe": false,
    "favorite": false
  },
  {
    "name": "Gratisography",
    "id": "90a49d06-c9a3-4163-8c33-f6b389159777",
    "selected": false,
    "url": "https://gratisography.com/?s=URL_HERE",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "Free Digital Photos",
    "id": "a307ca08-2d6f-4029-824b-a2d809accebb",
    "selected": false,
    "url": "https://www.freedigitalphotos.net/images/search.php?search=URL_HERE",
    "allowIframe": false,
    "favorite": false
  },
  {
    "name": "Free Images",
    "id": "6f1b9335-c6ca-4ec3-b92b-c03a8c7290fe",
    "selected": false,
    "url": "https://www.freeimages.com/search/URL_HERE",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "Pexels",
    "id": "2ad3b155-a361-4867-9272-e76907682602",
    "selected": false,
    "url": "https://www.pexels.com/search/URL_HERE/",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "Negative Space",
    "id": "94f24e1b-0257-409d-a4f1-6842ed6704b6",
    "selected": false,
    "url": "https://negativespace.co/?s=URL_HERE",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "Barn Images",
    "id": "0c9f0786-c661-4cfd-a832-b8f11d742c22",
    "selected": false,
    "url": "https://barnimages.com/?s=URL_HERE",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "Adobe Stock",
    "id": "eb1f55f7-7702-42af-a11c-79cc6ad761b3",
    "selected": false,
    "url": "https://stock.adobe.com/search?load_type=search&native_visual_search=&similar_content_id=&is_recent_search=&search_type=usertyped&k=URL_HERE",
    "allowIframe": false,
    "favorite": false
  },
  {
    "name": "Free Range Stock",
    "id": "47cf815a-513f-466a-bc26-e169ece850f9",
    "selected": false,
    "url": "https://freerangestock.com/search/all/URL_HERE",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "Freestocks.org",
    "id": "ab76b085-3aec-49e9-8c1f-66eafbec4bdf",
    "selected": false,
    "url": "https://freestocks.org/search/URL_HERE",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "Shutterstock",
    "id": "90c83d0d-f5f5-45e3-9857-189a82d2ed0d",
    "selected": false,
    "url": "https://www.shutterstock.com/search/URL_HERE",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "Picography",
    "id": "1249eee8-6a29-4ebd-a254-ac8d4c5be96d",
    "selected": false,
    "url": "https://picography.co/search/URL_HERE",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "Getty Images",
    "id": "70b63858-df5a-42f9-92f2-5c279c0b2aa0",
    "selected": false,
    "url": "https://www.gettyimages.com/photos/url_here?embeddable=true&license=rf&phrase=URL_HERE&sort=mostpopular#license",
    "allowIframe": false,
    "favorite": false
  },
  {
    "name": "Bigfoto",
    "id": "980e1e62-e4ad-43f6-bbe7-68f4c81df1e2",
    "selected": false,
    "url": "https://bigfoto.com/?s=URL_HERE",
    "allowIframe": false,
    "favorite": false
  },
  {
    "name": "IM Free",
    "id": "f96a9e68-e77a-40b0-b745-dd5aa66ff5d2",
    "selected": false,
    "url": "https://imcreator.com/?s=URL_HERE",
    "allowIframe": false,
    "favorite": false
  },
  {
    "name": "MMT Stock",
    "id": "8eaa2196-d539-429b-913e-40c1aa5552b4",
    "selected": false,
    "url": "https://focastock.com/?s=URL_HERE",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "ISO Republic",
    "id": "0ff4f1a0-959e-4804-8d1a-0c750b099b00",
    "selected": false,
    "url": "https://isorepublic.com/?s=URL_HERE",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "Compfight",
    "id": "a4aee4aa-7792-4c86-b333-9b0b6fe054b1",
    "selected": false,
    "url": "https://compfight.com/search/URL_HERE",
    "allowIframe": false,
    "favorite": false
  },
  {
    "name": "Morguefile",
    "id": "b4d97e93-31ff-4aa3-80ed-bc4dec61d601",
    "selected": false,
    "url": "https://morguefile.com/photos/morguefile/1/URL_HERE",
    "allowIframe": false,
    "favorite": false
  },
  {
    "name": "SkitterPhoto",
    "id": "647f52c9-04cb-47f5-89f2-862b705d7004",
    "selected": false,
    "url": "https://skitterphoto.com/photos/search/URL_HERE",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "Large Photos",
    "id": "71ac0c65-b1d5-49ca-b5f5-ba816df39d06",
    "selected": false,
    "url": "https://largephotos.net/?search=student&s=URL_HERE",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "Bigstockphoto",
    "id": "4914fc6e-c136-4d5b-8298-507bc0fc304f",
    "selected": false,
    "url": "https://www.bigstockphoto.com/search/URL_HERE",
    "allowIframe": false,
    "favorite": false
  },
  {
    "name": "Public Domain Pictures",
    "id": "78d70872-68ae-4d37-ae0f-aba0685aa650",
    "selected": false,
    "url": "https://www.publicdomainpictures.net/en/hledej.php?hleda=URL_HERE",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "Life of Pix",
    "id": "244ad396-0af8-48a4-894f-966a1fd31a78",
    "selected": false,
    "url": "https://www.lifeofpix.com/search/URL_HERE?",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "LibreShot",
    "id": "58c785d4-2a72-423c-8548-e8391b273163",
    "selected": false,
    "url": "https://libreshot.com/?s=URL_HERE",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "Stockvault",
    "id": "99a2ad1a-f737-4bb5-ae6b-cf3fc094b983",
    "selected": false,
    "url": "https://www.stockvault.net/free-photos/URL_HERE/",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "Rawpixel",
    "id": "b31550ad-f809-40ca-bd89-cf615a3fdb8d",
    "selected": false,
    "url": "https://www.rawpixel.com/search/URL_HERE",
    "allowIframe": false,
    "favorite": false
  },
  {
    "name": "Rgbstock",
    "id": "7fef9800-52f8-4cb6-b2e0-a1bc38a6f3f2",
    "selected": false,
    "url": "https://www.rgbstock.com/images/URL_HERE",
    "allowIframe": false,
    "favorite": false
  },
  {
    "name": "FoodiesFeed",
    "id": "8839f881-5ee9-42c2-8048-388b343a1529",
    "selected": false,
    "url": "https://www.foodiesfeed.com/?s=URL_HERE",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "DesignerPics",
    "id": "94a706a5-407a-4f23-bd3c-3a52d116ce9d",
    "selected": false,
    "url": "https://www.designerspics.com/?s=URL_HERE",
    "allowIframe": false,
    "favorite": false
  },
  {
    "name": "Picjumbo",
    "id": "820254c9-e70a-40ba-be31-ca840f0c778b",
    "selected": false,
    "url": "https://picjumbo.com/search/URL_HERE",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "New Old Stock",
    "id": "85af8a07-69de-43d4-9e51-74f959bc38f1",
    "selected": false,
    "url": "https://nos.twnsnd.co/search/URL_HERE",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "StokPic",
    "id": "526d3112-19a0-4b67-9f42-68aedf15f0a5",
    "selected": false,
    "url": "https://stokpic.com/?s=URL_HERE",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "Stocksy",
    "id": "d39bc728-fdd6-48a4-836a-5f9aa1e3a4c7",
    "selected": false,
    "url": "https://www.stocksy.com/search/URL_HERE?text=URL_HERE&page=1&sort=relevance",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "FreeMediaGoo",
    "id": "1fca9c4e-1d73-4b50-9d10-c0a053ea50dd",
    "selected": false,
    "url": "https://www.freemediagoo.com/?s=URL_HERE",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "123RF",
    "id": "00b2d307-e1ba-455a-830b-92253a8bf6cb",
    "selected": false,
    "url": "https://www.123rf.com/stock-photo/URL_HERE.html",
    "allowIframe": false,
    "favorite": false
  },
  {
    "name": "Splitshire",
    "id": "48541e71-3630-4d62-bb09-1a399889dc9d",
    "selected": false,
    "url": "https://www.splitshire.com/?s=URL_HERE",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "Kaboom Pics",
    "id": "9b245a94-18ff-4669-ab21-482e76f1c045",
    "selected": false,
    "url": "https://kaboompics.com/gallery?search=URL_HERE",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "iStock",
    "id": "1d1fbe5f-3a2a-4c71-9a60-92643d6a3dd9",
    "selected": false,
    "url": "https://www.istockphoto.com/photos/URL_HERE",
    "allowIframe": false,
    "favorite": false
  },
  {
    "name": "VisualHunt",
    "id": "7f6a41a0-b980-4309-b056-498d6e9568c0",
    "selected": false,
    "url": "https://visualhunt.com/search/instant/?q=URL_HERE",
    "allowIframe": false,
    "favorite": false
  },
  {
    "name": "Magdeleine",
    "id": "bd82db9a-2e9b-42ee-b85b-e47cd2cdbf63",
    "selected": false,
    "url": "https://magdeleine.co/?s=URL_HERE",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "Fancy Crave",
    "id": "5fea142f-c711-4778-9fd4-e72e39545008",
    "selected": false,
    "url": "https://fancycrave.com/?s=URL_HERE",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "ABSfreepics",
    "id": "910eff7d-f6d4-43d2-915b-8b44027e33b3",
    "selected": false,
    "url": "https://absfreepic.com/free-photos/URL_HERE.html",
    "allowIframe": false,
    "favorite": false
  },
  {
    "name": "Foter",
    "id": "befc1d01-7ffd-46ee-862a-e265b90c4398",
    "selected": false,
    "url": "https://foter.com/explore/search/?q=URL_HERE",
    "allowIframe": false,
    "favorite": false
  },
  {
    "name": "PhotoPin",
    "id": "b421f318-395f-487a-87e5-c69b7b7d71df",
    "selected": false,
    "url": "https://photopin.com/free-photos/URL_HERE",
    "allowIframe": false,
    "favorite": false
  },
  {
    "name": "Creative Commons",
    "id": "4bdafad8-5fc9-4500-a2f6-7f882eb45a14",
    "selected": false,
    "url": "https://search.creativecommons.org/search?q=URL_HERE",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "Bucketlistly",
    "id": "885177f1-7012-4274-ae12-6a7bb51109f5",
    "selected": false,
    "url": "https://www.bucketlistly.blog/photos/photo-search?search=URL_HERE",
    "allowIframe": false,
    "favorite": false
  },
  {
    "name": "Albumarium",
    "id": "ed292ca2-f27e-4278-a2f2-99ad460e4c82",
    "selected": false,
    "url": "https://albumarium.com/search?utf8=%E2%9C%93&q=URL_HERE",
    "allowIframe": false,
    "favorite": false
  },
  {
    "name": "500px",
    "id": "6a2ebc53-78fe-4509-842e-5560e348b61e",
    "selected": false,
    "url": "https://500px.com/search?q=URL_HERE&type=photos",
    "allowIframe": false,
    "favorite": false
  },
  {
    "name": "Jeshoots",
    "id": "39566356-929b-4362-941e-b106ee80be3d",
    "selected": false,
    "url": "https://jeshoots.com/?s=URL_HERE",
    "allowIframe": true,
    "favorite": false
  },
  {
    "name": "Freepik",
    "id": "aed7e84b-0d89-42b2-836a-8bc48adc5251",
    "selected": false,
    "url": "https://www.freepik.com/search?dates=any&format=search&page=1&query=URL_HERE&sort=popular",
    "allowIframe": false,
    "favorite": false
  },
  {
    "name": "Creativity 103",
    "id": "8dd042a3-12cf-45bb-afa7-85ca0d40ff4d",
    "selected": false,
    "url": "https://creativity103.com/search_results.php?q=URL_HERE",
    "allowIframe": false,
    "favorite": false
  },
  {
    "name": "Realistic Shots",
    "id": "3e3c862f-123a-431f-bdb9-2949a4d9ac04",
    "selected": false,
    "url": "https://realisticshots.com/search/URL_HERE",
    "allowIframe": true,
    "favorite": false
  }
]

export default list;
