/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import Button from "./Button";
import Modal from "react-modal";

Modal.setAppElement(document.body);

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export function Donate() {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="items-center w-full flex justify-center">
      <Button color="link" size="sm" className="text-sm bg-yellow-100 my-2 w-full mx-2 text-center" onClick={openModal}>
        Support &amp; Donate
      </Button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={() => {}}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Donate"
      >
        <h2 className="text-center w-full mb-8 text-xl">
          Donate and support projects like this to stay active.
        </h2>
        <div className="flex flex-wrap max-w-xl">
          <div className="text-center w-1/2 mb-10">
            <a
              className="block text-center flex flex-col items-center"
              href="bitcoin:35fgticmNTYqx2vqbUPh8FXPN7tX6TaYgB"
              target="_blank"
            >
              <img
                style={{ maxWidth: "100px" }}
                src="/images/bitcoin-35fgticmNTYqx2vqbUPh8FXPN7tX6TaYgB.png"
                alt="BTC"
              />
            </a>
            <h3 className="mt-4">Bitcoin</h3>
            <h4 className="text-xs">35fgticmNTYqx2vqbUPh8FXPN7tX6TaYgB</h4>
          </div>
          <div className="text-center w-1/2 mb-10">
            <a
              className="block text-center flex flex-col items-center"
              href="ethereum:0xBF909faAe490748BFA84A0DD7eC51e18117b612a"
              target="_blank"
            >
              <img
                style={{ maxWidth: "100px" }}
                src="/images/ethereum-0xBF909faAe490748BFA84A0DD7eC51e18117b612a.png"
                alt="ETH"
              />
            </a>
            <h3 className="mt-4">Ethereum</h3>
            <h4 className="text-xs">
              0xBF909faAe490748BFA84A0DD7eC51e18117b612a
            </h4>
          </div>
          <div className="text-center w-1/2 mb-10">
            <a
              className="block text-center flex flex-col items-center"
              href="litecoin:MHTLbbdmmjXLNnc6m7YNutbgKVtrWxkPUT"
              target="_blank"
            >
              <img
                style={{ maxWidth: "100px" }}
                src="/images/litecoin-MHTLbbdmmjXLNnc6m7YNutbgKVtrWxkPUT.png"
                alt="LTC"
              />
            </a>
            <h3 className="mt-4">Litecoin</h3>
            <h4 className="text-xs">MHTLbbdmmjXLNnc6m7YNutbgKVtrWxkPUT</h4>
          </div>
          <div className="text-center w-1/2 mb-10">
            <a
              className="block text-center flex flex-col items-center"
              href="raven:RKR7hvkgMXMo2hgRUQHMSswpa8tKFm9WMS"
              target="_blank"
            >
              <img
                style={{ maxWidth: "100px" }}
                src="/images/ravencoin-RKR7hvkgMXMo2hgRUQHMSswpa8tKFm9WMS.png"
                alt="LTC"
              />
            </a>
            <h3 className="mt-4">Ravencoin</h3>
            <h4 className="text-xs">RKR7hvkgMXMo2hgRUQHMSswpa8tKFm9WMS</h4>
          </div>
        </div>
        <div className="flex justify-end">
          <button onClick={closeModal}>close</button>
        </div>
      </Modal>
    </div>
  );
}

export default Donate;
