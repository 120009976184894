/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import classNames from "classnames";
import Button from "./Button";
import Donate from "./Donate";
import Input from "./Input";
import Warning from "./Warning";

export function Sidebar({
  handleSubmit,
  handleClick,
  links,
  setLinks,
  showIframeOnly,
  toggleIframeOnly,
  setIsOpen,
  isOpen,
}) {
  const [search, setSearch] = useState("");
  const [newTabs, setNewTabs] = useState(false);
  const [linkFilter, setLinkFilter] = useState("");

  function _handleChange(e) {
    const { value } = e.target;
    setSearch(value);
  }

  function _handleClick(state) {
    if (!state) {
      setSearch("");
    }
    handleClick(state);
  }

  function _showIframeOnly() {
    toggleIframeOnly(!showIframeOnly);
  }

  function _handleSelect(linkId) {
    const arr = [...links];
    const index = arr.findIndex((x) => x.id === linkId);
    arr[index].selected = !arr[index].selected;
    const storage = arr.map((x) => ({
      id: x.id,
      selected: x.selected,
      favorite: x.favorite,
    }));
    localStorage.setItem("site-links", JSON.stringify(storage));
    setLinks(arr);
  }

  function _handleFavorite(linkId) {
    const arr = [...links];
    const index = arr.findIndex((x) => x.id === linkId);
    arr[index].favorite = !arr[index].favorite;
    const storage = arr.map((x) => ({
      id: x.id,
      selected: x.selected,
      favorite: x.favorite,
    }));
    localStorage.setItem("site-links", JSON.stringify(storage));
    setLinks(arr);
  }

  function _handleSubmit(e) {
    e.preventDefault();
    handleSubmit(search, newTabs);
  }

  const linkedParsed = links.filter((x) => {
    if (showIframeOnly) {
      return x.allowIframe === showIframeOnly;
    }
    if (linkFilter) {
      return x.name.toLowerCase().includes(linkFilter.toLowerCase());
    }
    return x;
  });

  linkedParsed.sort((a, b) => (a.favorite ? -1 : 1));

  const sidebarClasses = classNames(
    "w-52 fixed border-r border-solid border-gray-300 h-full top-0 left-0 bg-white z-30 flex flex-col",
    "transition-all duration-1000 transform ease-in-out",
    { "-translate-x-full": !isOpen }
  );

  return (
    <aside id="app-sidebar" className={sidebarClasses}>
      <div id="app-logo" className="relative px-4 pt-4">
        <img src="/logo.png" alt="Batch Image Search" />
        <h1>Batch Image Search</h1>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="absolute right-0 top-16 leading-none bg-white py-3 px-2 border border-solid border-gray-300 -mr-8 border-l-0 hover:bg-blue-500 text-black hover:text-white duraton-500 transition ease-in-out"
        >
          {isOpen ? "🡄" : "🡆"}
        </button>
      </div>
      <form className="form p-3">
        <Input
          type="text"
          name="search"
          value={search}
          onChange={_handleChange}
          className="bg-gray-100 mb-2 w-full"
          placeholder="Search..."
          size="sm"
          required
        />
        <div className="flex justify-between">
          <Button
            tabIndex="0"
            type="button"
            size="sm"
            onClick={() => _handleClick(false)}
          >
            Reset
          </Button>
          <Button
            tabIndex="0"
            type="button"
            size="sm"
            onClick={() => _handleClick(true)}
          >
            All
          </Button>
          <Button tabIndex="0" type="submit" size="sm" onClick={_handleSubmit}>
            Search
          </Button>
        </div>
      </form>
      <ul className="new-tabs px-2">
        <li className="cursor-pointer hover:bg-gray-200 rounded px-1">
          <input
            id="newTabs"
            type="checkbox"
            value={newTabs}
            onChange={() => setNewTabs(!newTabs)}
            className="cursor-pointer"
          />
          <label htmlFor="newTabs" className="pl-1 text-sm cursor-pointer">
            Open in New Tabs?
          </label>
        </li>
        <li className="cursor-pointer hover:bg-gray-200 rounded px-1">
          <input
            id="showIframeOnly"
            type="checkbox"
            value={showIframeOnly}
            onChange={() => _showIframeOnly(!showIframeOnly)}
          />
          <label
            htmlFor="showIframeOnly"
            className="pl-1 text-sm cursor-pointer"
          >
            Show Iframe Only
          </label>
        </li>
      </ul>
      {newTabs && <Warning />}
      <hr className="my-2" />
      <div className="px-3">
        <Input
          type="text"
          value={linkFilter}
          onChange={(e) => setLinkFilter(e.target.value)}
          className="mb-4 w-full bg-gray-100"
          placeholder="Filter..."
          size="sm"
        />
      </div>
      <ul className="filters mx-2 flex-1">
        {linkedParsed.map((link, index) => (
          <li
            key={link.id}
            className="cursor-pointer hover:bg-gray-200 rounded px-1 flex items-center"
          >
            <span className="flex-1 flex items-center">
              <input
                id={link.id}
                type="checkbox"
                checked={link.selected}
                onChange={() => _handleSelect(link.id)}
                className="cursor-pointer"
              />
              <label htmlFor={link.id} className="pl-2 cursor-pointer flex-1 text-sm">
                {link.name}
              </label>
            </span>
            <button
              onClick={() => _handleFavorite(link.id)}
              className={
                link.favorite ? "text-yellow-500 outline-none" : "outline-none"
              }
            >
              {link.favorite ? "★" : "☆"}
            </button>
          </li>
        ))}
      </ul>
      <Donate/>
    </aside>
  );
}
