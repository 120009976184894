import React from "react";
import classNames from "classnames";

export function Button(props) {
  let { tag: Tag } = props;
  const {
    to,
    color,
    size,
    block,
    onClick,
    className,
    children,
    ...attributes
  } = props;

  const classes = classNames(
    {
      "bg-blue-500 hover:bg-blue-600 focus:ring-1 focus:ring-blue-600 active:bg-blue-600 text-white":
        color === "primary" || !color,
    },
    {
      "bg-gray-500 hover:bg-gray-600 focus:ring-1 focus:ring-gray-600 active:bg-gray-600 text-white":
        color === "secondary",
    },
    {
      "bg-yellow-500 hover:bg-yellow-600 focus:ring-1 focus:ring-yellow-600 active:bg-yellow-600 text-white":
        color === "warning",
    },
    {
      "bg-red-500 hover:bg-red-600 focus:ring-1 focus:ring-red-600 active:bg-red-600 text-white":
        color === "danger",
    },
    {
      "bg-green-500 hover:bg-green-600 focus:ring-1 focus:ring-green-600 active:bg-green-600 text-white":
        color === "success",
    },
    {
      "bg-transparent hover:bg-black hover:text-white focus:ring-1 focus:ring-black active:bg-black text-black active:text-black focus:text-black":
        color === "link",
    },
    { "py-1 px-3": size === "sm" },
    { "py-2 px-4": size === "md" || !size },
    { "w-full": block },
    "rounded transition-colors duration-300 disabled:opacity-50 cursor-pointer",
    className
  );

  Tag = "a" ? "a" : "button";

  return (
    <Tag onClick={onClick} {...attributes} className={classes}>
      {children}
    </Tag>
  );
}

export default Button;
